import { createRouter } from '@avriopolis/common'

export default createRouter([
  {
    name: '',
    path: '/',
    meta: { authRequired: true },
    component: async () => import('@/views/App.vue'),
    children: [
      {
        name: 'dashboard',
        path: '/',
        component: async () => import('@/views/pages/Dashboard.vue'),
      },
      {
        name: 'user',
        path: '/user',
        component: async () => import('@/views/pages/Profile.vue'),
      },
    ],
  },
])
