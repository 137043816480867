<template>
  <div ref="toastRef" class="toast" role="alert" aria-live="assertive" aria-atomic="true" v-show="show">
    <div class="toast-header">
      <strong class="me-auto">Avriopolis</strong>
      <small>{{ relativeTime }}</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">{{ message }}</div>
  </div>
</template>

<script lang="ts" scoped>
import { defineComponent, onMounted, ref, watchEffect, computed } from 'vue'

import { Toast } from 'bootstrap'

import moment from 'moment'

export default defineComponent({
  name: 'Toast',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 10000,
    },
    timestamp: {
      type: Number,
      default: Date.now(),
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const toastRef = ref()
    let toastInstance: Toast
    let timeoutInstance: number

    const relativeTime = ref(moment(props.timestamp).fromNow())

    watchEffect(() => {
      const toShow = props.show
      if (toastInstance) {
        if (toShow) {
          if (props.timestamp > Date.now()) {
            if (timeoutInstance) {
              clearTimeout(timeoutInstance)
            }
            timeoutInstance = setTimeout(() => {
              relativeTime.value = moment(props.timestamp).fromNow()
              toastInstance.show()
            }, props.timestamp - Date.now())
          } else {
            relativeTime.value = moment(props.timestamp).fromNow()
            toastInstance.show()
          }
        } else {
          toastInstance.hide()
        }
      }
    })

    onMounted(() => {
      toastInstance = new Toast(toastRef.value, { delay: props.delay })
      if (props.show) {
        if (props.timestamp > Date.now()) {
          timeoutInstance = setTimeout(() => {
            relativeTime.value = moment(props.timestamp).fromNow()
            toastInstance.show()
          }, props.timestamp - Date.now())
        } else {
          relativeTime.value = moment(props.timestamp).fromNow()
          toastInstance.show()
        }
      }
    })

    return {
      toastRef,
      relativeTime,
    }
  },
})
</script>
