import axios from 'axios'
import { NavigationGuard } from 'vue-router'

declare var OktaAuth: any

const oktaSettings = {
  issuer: 'https://dev-46660671.okta.com/oauth2/default',
  clientId: '0oa61csevA18WZ5Jc5d6',
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  tokenManager: {
    autoRenew: true,
  },
}

const oktaAuth = new OktaAuth(oktaSettings)

oktaAuth.authStateManager.subscribe((state: any) => {
  if (!axios.defaults.headers.Authorization) {
    if (state.isAuthenticated) {
      axios.defaults.headers.Authorization = `Bearer ${state.accessToken.accessToken}`
    }
  } else {
    if (!state.isAuthenticated) {
      signOut()
    }
  }
})

oktaAuth.tokenManager.on('renewed', function (key: string, { accessToken }: any) {
  if (key === 'accessToken') {
    axios.defaults.headers.Authorization = `Bearer ${accessToken}`
  }
})

oktaAuth.start()

export const signIn = async () => {
  await oktaAuth.signInWithRedirect()
}

export const validate = async () => {
  if (oktaAuth.isLoginRedirect()) {
    await oktaAuth.storeTokensFromRedirect()
    window.history.replaceState(history.state, window.document.title, window.location.origin)
    return true
  }
  return false
}

export const isAuthenticated = async (): Promise<boolean> => {
  return !!(await oktaAuth.isAuthenticated())
}

export const signOut = async () => {
  await oktaAuth.signOut()
}

export const routeGuard: NavigationGuard = async (_to, _from, next) => {
  await oktaAuth.isAuthenticated()
  next()
}
