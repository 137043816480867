import axios from 'axios'
import { validate, signOut, signIn, isAuthenticated } from './store/okta'
import { fetchUser } from './store/user'

axios.defaults.headers.Authorization = undefined
axios.defaults.withCredentials = true
axios.defaults.baseURL = import.meta.env.VITE_API_URL as string

axios.interceptors.request.use(
  (config) => config,
  function (error) {
    if (error?.response?.status === 401) {
      signOut()
    }
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      signOut()
    }
    return Promise.reject(error)
  },
)

export * as Components from './components'
export { default as createRouter } from './router'
export { default as i18n } from './i18n'

export async function ready() {
  try {
    await validate()
  } catch (e) {}
  try {
    if (!(await isAuthenticated())) {
      return false
    } else {
      await fetchUser()
      return true
    }
  } catch (e) {
    console.log(e)
  }
  return false
}
