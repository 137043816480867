import { ref, computed } from 'vue'
import axios from 'axios'

import i18n from '../i18n'

type User = {
  id: number
  language: string
  first_name: string
  last_name: string
  email: string
  full_name: string
  avatar: string
  role: {
    name: string
  }
}

export const user = ref<User>()

export const fetchUser = async () => {
  const response = await axios.get('/users/me?fields=*,role.*')
  if (response?.data?.data) {
    const userData = response.data.data
    const hasName = userData.first_name && userData.last_name

    if (i18n.global.availableLocales.includes(userData.language)) {
      i18n.global.locale = userData.language
    }

    user.value = {
      ...userData,
      full_name: hasName ? `${userData.first_name} ${userData.last_name}` : userData.email,
    }
  }
}

export default computed(() => user.value)
