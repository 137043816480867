import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { routeGuard as activityGuard } from '../store/activity'
import { routeGuard as authGuard } from '../store/okta'

export default function (routes: RouteRecordRaw[]) {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })
  router.beforeEach(activityGuard)
  router.beforeEach(authGuard)
  return router
}
